import React from "react";

const LineArrow = () => {
    return (
        <>
            <svg
                width="265"
                height="6"
                viewBox="0 0 265 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.48167 4.22573C87.7577 2.55537 174.532 2.28989 261.746 5.00006C264.795 5.09962 264.753 2.61068 261.746 2.489C175.096 -0.962325 86.9432 -1.06188 0.48167 3.70582C-0.144863 3.739 -0.165748 4.23679 0.48167 4.22573Z"
                    fill="currentColor"
                />
            </svg>
        </>
    );
};

export default LineArrow;

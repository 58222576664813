import React, { useState } from "react";
import { useTranslation } from "next-export-i18n";
import Image from "next/image";
import Link from "next/link";
import SwiperClass, { EffectFade, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/autoplay";

import shape_2 from "@assets/img/home/slider-bot-right.png";
import shape_3 from "@assets/img/hero/shape-3.png";
import shape_4 from "@assets/img/home/slider-bot-left.png";
import shape_5 from "@assets/img/hero/shape-7.png";
import shape_7 from "@assets/img/home/slider-top-left.png";
import white_label_shape from "@assets/img/home/white-label-dmp-dsp.svg";
import discover_the_future_shape from "@assets/img/home/discover-the-future-of.svg";
import realize_your_customers_shape from "@assets/img/home/realize-your-customers.svg";
import lower_campaign_pain_shape from "@assets/img/home/lower-campaign-pain.svg";
import service_shape from "@assets/img/hero/shape-5.png";
import AngleArrow from "@/src/svg/angle-arrow";
import LineArrow from "@/src/svg/line-arrow";
import SliderIndicatorEmpty from "@assets/img/home/slider-indicator-empty.svg";
import SliderIndicatorFull from "@assets/img/home/slider-indicator-full.svg";

// slider setting
const setting = {
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "slide",
    speed: 1500,
    autoplay: {
        delay: 9000,
        disableOnInteraction: false,
    },
    // Navigation arrows
    navigation: {
        nextEl: ".hero-button-next-1",
        prevEl: ".hero-button-prev-1",
    },
};

// shapes
const shapes = [
    { id_cls: 8, img: white_label_shape, slider_id: 0 },
    { id_cls: 8, img: discover_the_future_shape, slider_id: 1 },
    { id_cls: 8, img: realize_your_customers_shape, slider_id: 2 },
    { id_cls: 8, img: lower_campaign_pain_shape, slider_id: 3 },
    { id_cls: 9, img: shape_7 },
    { id_cls: 2, img: shape_2 },
    { id_cls: 3, img: shape_3 },
    { id_cls: 4, img: shape_4 },
    { id_cls: 7, img: shape_5 },
];

const HeroSlider = () => {
    const { t } = useTranslation();

    const [swiper, setSwiper] = useState<SwiperClass>();
    const [currentSlider, setCurrentSlider] = useState(0);

    const handleSlideTo = (index: number) => () => swiper!.slideToLoop(index);
    const handleSlideChange = (swiper: SwiperClass) =>
        setCurrentSlider(swiper?.realIndex);

    const sliderData = [
        {
            id: 0,
            bg_img: "/assets/img/home/slider-overlay.svg",
            subtitle: (
                <>
                    {t("home.slider.slide_1.subtitle.secondary_1")}
                    <span className="title-pre-color">
                        {t("home.slider.slide_1.subtitle.primary")}
                    </span>
                </>
            ),
            hero_title_1: t("home.slider.slide_1.title.secondary"),
            hero_title_2: t("home.slider.slide_1.title.primary"),
            quotes: t("home.slider.slide_1.quotes"),
            button: t("home.slider.slide_1.button"),
            link: "/platform",
            icon: "fa-solid fa-bullseye-pointer fa-2x",
        },
        {
            id: 1,
            bg_img: "/assets/img/home/slider-overlay.svg",
            subtitle: (
                <>
                    <span className="title-pre-color">
                        {t("home.slider.slide_2.subtitle.primary")}
                    </span>
                    . {t("home.slider.slide_2.subtitle.secondary_1")}
                    {t("home.slider.slide_2.subtitle.secondary_2")}
                </>
            ),
            hero_title_1: t("home.slider.slide_2.title.secondary"),
            hero_title_2: t("home.slider.slide_2.title.primary"),
            quotes: t("home.slider.slide_2.quotes"),
            button: t("home.slider.slide_2.button"),
            link: "/contact",
            icon: "fa-solid fa-trophy fa-2x",
        },
        {
            id: 2,
            bg_img: "/assets/img/home/slider-overlay.svg",
            subtitle: (
                <>
                    <span className="title-pre-color">
                        {t("home.slider.slide_3.subtitle.secondary_1")}
                    </span>
                    {t("home.slider.slide_3.subtitle.primary")}
                </>
            ),
            hero_title_1: t("home.slider.slide_3.title.secondary"),
            hero_title_2: t("home.slider.slide_3.title.primary"),
            quotes: t("home.slider.slide_3.quotes"),
            button: t("home.slider.slide_3.button"),
            link: "/contact",
            icon: "fa-solid fa-chart-mixed fa-2x",
        },
        {
            id: 3,
            bg_img: "/assets/img/home/slider-overlay.svg",
            subtitle: (
                <>
                    {t("home.slider.slide_4.subtitle.secondary_1")}
                    <span className="title-pre-color">
                        {t("home.slider.slide_4.subtitle.primary")}
                    </span>
                </>
            ),
            hero_title_1: t("home.slider.slide_4.title.secondary"),
            hero_title_2: t("home.slider.slide_4.title.primary"),
            quotes: t("home.slider.slide_4.quotes"),
            button: t("home.slider.slide_4.button"),
            link: "/testimonials",
            icon: "fa-solid fa-money-check-dollar-pen fa-2x",
        },
    ];

    return (
        <>
            <section className="tp-hero-area pb-95">
                <div className="tp-hero-wrapper p-relative">
                    <div className="hero-active-1 swiper-container">
                        {/* @ts-ignore */}
                        <Swiper
                            {...setting}
                            loop={true}
                            modules={[Navigation, EffectFade, Autoplay]}
                            onSlideChange={handleSlideChange}
                            onSwiper={setSwiper}
                        >
                            {sliderData.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <div className="tp-hero-inner-1">
                                        <div className="container">
                                            <div className="tp-hero-shape">
                                                {shapes
                                                    .filter(
                                                        (shape) =>
                                                            shape.slider_id ===
                                                                undefined ||
                                                            shape.slider_id ===
                                                                item.id
                                                    )
                                                    .map((shape, index) => (
                                                        <Image
                                                            key={index}
                                                            loading="eager"
                                                            className={`shape-${shape.id_cls} tp-hero-bg-img`}
                                                            src={shape.img}
                                                            alt="theme-pure"
                                                        />
                                                    ))}
                                            </div>
                                            <div className="tp-hero-1">
                                                <div
                                                    className="tp-hero-bg tp-hero-overlay p-relative"
                                                    style={{
                                                        backgroundImage: `url(${item.bg_img})`,
                                                    }}
                                                />
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        <div className="tp-hero-content p-relative">
                                                            <div className="tp-hero-title-wrapper">
                                                                <span className="tp-section-title__pre p-relative">
                                                                    {
                                                                        item.subtitle
                                                                    }
                                                                    <AngleArrow />
                                                                </span>
                                                                <h3 className="tp-hero-title">
                                                                    {
                                                                        item.hero_title_1
                                                                    }
                                                                    <LineArrow />
                                                                    <br />{" "}
                                                                    <span className="title-secondary title-color">
                                                                        {
                                                                            item.hero_title_2
                                                                        }
                                                                    </span>
                                                                </h3>
                                                                <div className="tp-hero-btn">
                                                                    <Link
                                                                        className="slider_btn"
                                                                        href={
                                                                            item.link
                                                                        }
                                                                    >
                                                                        {
                                                                            item.button
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tp-hero-service"
                                        style={{
                                            backgroundImage: `url(/assets/img/hero/shape-6.png)`,
                                        }}
                                    >
                                        <div className="tp-hero-service-shape">
                                            <Image
                                                src={service_shape}
                                                loading="eager"
                                                alt="theme-pure"
                                                className="tp-hero-service-shape-img"
                                            />
                                        </div>
                                        <p>{item.quotes}</p>
                                        <div className="tp-hero-service-quote">
                                            <i className={item.icon} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="tp-hero-nav">
                        {/* <button
                            type="button"
                            className="hero-button-prev-1 alt-color slider_arrow"
                        >
                            <Image
                                src={SliderBtnLeft}
                                alt="theme-pure"
                                fill={true}
                            />
                        </button> */}
                        {sliderData.map((item) => (
                            <button
                                key={`slider-dot-${item.id}`}
                                onClick={handleSlideTo(item.id)}
                                className="slider_dot"
                            >
                                <Image
                                    src={
                                        item.id === currentSlider
                                            ? SliderIndicatorFull
                                            : SliderIndicatorEmpty
                                    }
                                    loading="eager"
                                    alt="theme-pure"
                                    fill={true}
                                />
                            </button>
                        ))}
                        {/* <button
                            type="button"
                            className="hero-button-next-1 alt-color slider_arrow"
                        >
                            <Image
                                src={SliderBtnRight}
                                alt="theme-pure"
                                fill={true}
                            />
                        </button> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroSlider;

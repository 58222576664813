import { useEffect } from "react";
import SEO from "../common/seo";
import HomeOne from "../components/home";
import Wrapper from "../layout/wrapper";

declare global {
    interface Window {
        trackingStoreSession: any;
        trackingConversion: any;
    }
}
let sdkLoaded = false;

const Home = () => {
    // Load NUVIAD SDK on first load + report conversion 0
    if (!sdkLoaded) {
        useEffect(() => {
            const head = document.getElementsByTagName("head")[0];
            const trackingScript = document.createElement("script");
            // @ts-ignore
            trackingScript.setAttribute("async", true);
            trackingScript.setAttribute(
                "src",
                "//static.rtbaxs.io/tracking.min.js?ts=" + new Date().getTime()
            );

            trackingScript.addEventListener("load", function () {
                console.log("Loaded NUVIAD tracking SDK");
                sdkLoaded = true;
                window.trackingStoreSession();
                if (window.trackingConversion) {
                    const CONVERSION_STAGE = "0";
                    console.log(
                        "Sending conversion for stage",
                        CONVERSION_STAGE
                    );
                    window.trackingConversion(CONVERSION_STAGE);
                } else {
                    console.error("window.trackingConversion is not defined");
                }
            });

            trackingScript.addEventListener("error", function () {
                console.log("Failed to load NUVIAD tracking SDK");
            });

            head.appendChild(trackingScript);

            return () => {
                head.removeChild(trackingScript);
            };
        }, []);
    }

    return (
        <Wrapper>
            <SEO pageTitle={"NUVIAD Main Home"} />
            <HomeOne />
        </Wrapper>
    );
};

export default Home;

import { useTranslation } from "next-export-i18n";
import Image from "next/image";
import SwiperClass, { Navigation } from "swiper";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import AngleArrow from "@/src/svg/angle-arrow";

import about_img_1 from "@assets/img/about/icon-smart-targeting.svg";
import about_img_2 from "@assets/img/about/icon-proven-efficiency.svg";
import about_img_3 from "@assets/img/about/icon-data-empowered.svg";
import LineArrowTwo from "@/src/svg/line-arrow-2";
import about from "@assets/img/home/redefining-dsp-dmp-performance.png";
import SliderIndicatorEmpty from "@assets/img/home/slider-indicator-empty.svg";
import SliderIndicatorFull from "@assets/img/home/slider-indicator-full.svg";

const setting = {
    slidesPerView: 3,
    spaceBetween: 82,
    breakpoints: {
        "1860": {},
        "1800": {
            spaceBetween: 40,
        },
        "1701": {},
        "1600": {},
        "1560": {},
        "1400": {
            spaceBetween: 60,
        },
        "1200": {
            spaceBetween: 30,
            slidesPerView: 2,
        },
        "992": {
            spaceBetween: 60,
            slidesPerView: 2,
        },
        "767": {
            slidesPerView: 2,
        },
        "576": {
            slidesPerView: 1,
        },
        "0": {
            slidesPerView: 1,
        },
    },
};

const AboutArea = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [currentSlider, setCurrentSlider] = useState(0);

    const handleSlideTo = (index: number) => () => swiper!.slideToLoop(index);
    const handleSlideChange = (swiper: SwiperClass) =>
        setCurrentSlider(swiper?.realIndex);

    const aboutData = [
        {
            id: 0,
            img: about_img_1,
            title: t("home.about.blocks.block_1.title"),
            description: t("home.about.blocks.block_1.description"),
        },
        {
            id: 1,
            img: about_img_2,
            title: t("home.about.blocks.block_2.title"),
            description: t("home.about.blocks.block_2.description"),
        },
        {
            id: 2,
            img: about_img_3,
            title: t("home.about.blocks.block_3.title"),
            description: t("home.about.blocks.block_3.description"),
        },
    ];

    return (
        <>
            <section className="tp-about-area pb-45 p-relative">
                <div className="container-fluid">
                    <div className="row about">
                        <div className="p-relative col-xl-4 col-lg-12 about_img">
                            <div className="text-center text-xl-start fadeLeft">
                                <Image
                                    src={about}
                                    alt="theme-pure"
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-10">
                            <div className="tp-about-wrapper">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="tp-about-title-wrapper p-relative">
                                            <span className="tp-section-title__pre about_subtitle">
                                                <span className="title-pre-color">
                                                    {t(
                                                        "home.about.subtitle.primary"
                                                    )}
                                                </span>
                                                {t(
                                                    "home.about.subtitle.secondary"
                                                )}
                                                <AngleArrow />
                                            </span>
                                            <h3 className="tp-section-title about_header">
                                                <span className="about_title">
                                                    {t(
                                                        "home.about.title.primary"
                                                    )}
                                                </span>
                                                {t(
                                                    "home.about.title.secondary"
                                                )}
                                                <span className="title-left-shape">
                                                    <LineArrowTwo />
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp-about-item-wrapper">
                                    <Swiper
                                        {...setting}
                                        modules={[Navigation]}
                                        loop={true}
                                        className="about-active swiper-container"
                                        onSlideChange={handleSlideChange}
                                        onSwiper={setSwiper}
                                    >
                                        {aboutData.map((item, i) => (
                                            <SwiperSlide
                                                key={i}
                                                className="tp-about-item mb-30"
                                            >
                                                <div className="tp-about-item-thumb">
                                                    <Image
                                                        src={item.img}
                                                        alt="theme-pure"
                                                    />
                                                </div>
                                                <div className="tp-about-item-content">
                                                    <h4 className="about-title">
                                                        {item.title}
                                                    </h4>
                                                    <p className="about_description">
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="tp-about-item-content-nav">
                                        {aboutData.map((item) => (
                                            <button
                                                key={`about-slider-dot-${item.id}`}
                                                onClick={handleSlideTo(item.id)}
                                            >
                                                <Image
                                                    src={
                                                        item.id ===
                                                        currentSlider
                                                            ? SliderIndicatorFull
                                                            : SliderIndicatorEmpty
                                                    }
                                                    alt="theme-pure"
                                                    width={20}
                                                />
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutArea;

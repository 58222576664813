import React, { useState } from "react";
import { useTranslation } from "next-export-i18n";
import Image from "next/image";
import SwiperClass, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import quot from "@assets/img/testimonial/quot-2.png";
import SliderIndicatorEmpty from "@assets/img/home/slider-indicator-empty.svg";
import SliderIndicatorFull from "@assets/img/home/slider-indicator-full.svg";
import SliderArrowLeft from "@assets/img/home/slider-btn-left.svg";
import SliderArrowRight from "@assets/img/home/slider-btn-right.svg";

const setting = {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    // Navigation arrows
    navigation: {
        nextEl: ".testimonial-button-next-1",
        prevEl: ".testimonial-button-prev-1",
    },
};

const TestimonialArea = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [currentSlider, setCurrentSlider] = useState(0);

    const handleSlideTo = (index: number) => () => swiper!.slideToLoop(index);
    const handleSlideChange = (swiper: SwiperClass) =>
        setCurrentSlider(swiper?.realIndex);

    const testimonialData = [
        {
            id: 0,
            name: "Alex Vinikov",
            title: t("home.testimonials.testimonial_1.title"),
            company: "Nova Media",
            link: "https://www.novamedia.co.il/",
            description: t("home.testimonials.testimonial_1.description"),
        },
        {
            id: 1,
            name: "Julio Aguayo",
            title: t("home.testimonials.testimonial_2.title"),
            company: "Duo Advertising",
            link: "https://www.duoadvertising.com/",
            description: t("home.testimonials.testimonial_2.description"),
        },
        {
            id: 2,
            name: "Mariela Fernande",
            title: t("home.testimonials.testimonial_3.title"),
            company: "MKT",
            link: "https://www.mktintelligence.net/",
            description: t("home.testimonials.testimonial_3.description"),
        },
    ];

    return (
        <>
            <section className="tp-testimonial-2-area p-relative pt-150 pb-120 mt-20">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="circle-animation business">
                                <div className="circle-animation business-2">
                                    <span className="tp-circle-8" />
                                    <span className="tp-circle-6" />
                                    <div className="circle-animation business-3">
                                        <span className="tp-circle-7" />
                                    </div>
                                    <div className="circle-animation business-4">
                                        <span className="tp-circle-5" />
                                    </div>
                                </div>
                            </div>

                            <Swiper
                                {...setting}
                                modules={[Navigation]}
                                className="testimonial-2-active swiper-container"
                                onSlideChange={handleSlideChange}
                                onSwiper={setSwiper}
                            >
                                {testimonialData.map((item, i) => (
                                    <SwiperSlide
                                        key={i}
                                        className="swiper-slide"
                                    >
                                        <div className="testimonial-item">
                                            <div className="tp-testimonial-2-content">
                                                <div className="tp-testimonial-2-thumb text-center">
                                                    <Image
                                                        src={quot}
                                                        alt="theme-pure"
                                                    />
                                                </div>
                                                <div className="tp-testimonial-2-info">
                                                    <h4 className="tp-testimonial-2-info-title text-center">
                                                        {item.name}
                                                    </h4>
                                                    <p className="tp-testimonial-2-info-designation text-center">
                                                        {item.title}{" "}
                                                        {t(
                                                            "home.testimonials.at"
                                                        )}{" "}
                                                        <a
                                                            href={item.link}
                                                            target="_blank"
                                                        >
                                                            {item.company}
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className="tp-testimonial-2-text text-center mb-20">
                                                    <p>“{item.description}”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className="tp-testimonial-2-nav d-none d-lg-block">
                                <button
                                    type="button"
                                    className="testimonial-button-next-1 hero-button-next-1 alt-color slider_arrow slider_arrow-next"
                                >
                                    <Image
                                        src={SliderArrowRight}
                                        alt="theme-pure"
                                        fill={true}
                                    />
                                </button>
                                <button
                                    type="button"
                                    className="testimonial-button-prev-1 hero-button-prev-1 alt-color slider_arrow slider_arrow-prev"
                                >
                                    <Image
                                        src={SliderArrowLeft}
                                        alt="theme-pure"
                                        fill={true}
                                    />
                                </button>
                            </div>

                            <div className="tp-about-item-content-nav">
                                {testimonialData.map((item) => (
                                    <button
                                        key={`about-slider-dot-${item.id}`}
                                        onClick={handleSlideTo(item.id)}
                                    >
                                        <Image
                                            src={
                                                item.id === currentSlider
                                                    ? SliderIndicatorFull
                                                    : SliderIndicatorEmpty
                                            }
                                            alt="theme-pure"
                                            width={20}
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TestimonialArea;
function setCurrentSlider(realIndex: any) {
    throw new Error("Function not implemented.");
}

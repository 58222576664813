import React from "react";

const LineArrowTwo = () => {
    return (
        <>
            <svg
                width="194"
                height="5"
                viewBox="0 0 194 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M193.649 4.22307C129.517 2.55338 65.7543 2.288 1.66891 4.99709C-0.571625 5.09661 -0.540924 2.60867 1.66891 2.48703C65.34 -0.962917 130.116 -1.06243 193.649 3.70336C194.109 3.73653 194.125 4.23412 193.649 4.22307Z"
                    fill="#EF5A32"
                />
            </svg>
        </>
    );
};

export default LineArrowTwo;

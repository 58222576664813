import React from "react";
import ModalVideo from "react-modal-video";

interface VideoPopupProps {
    isVideoOpen: any;
    setIsVideoOpen: any;
    videoId: any;
}

const VideoPopup = (props: VideoPopupProps) => {
    const { isVideoOpen, setIsVideoOpen, videoId } = props;
    return (
        <>
            <ModalVideo
                channel="youtube"
                // @ts-ignore
                autoplay={true}
                isOpen={isVideoOpen}
                videoId={videoId}
                onClose={() => setIsVideoOpen(false)}
            />
        </>
    );
};

export default VideoPopup;

import React from "react";
import HeaderOne from "@/src/layout/headers/header";
import HeroSlider from "./hero-slider";
import AboutArea from "./about-area";
import ServiceArea from "./service-area";
import Footer from "@/src/layout/footers/footer";
import TestimonialArea from "./testimonial-area";
import ComprehensiveSpeedArea from "./comprehensive-speed";

const Home = () => {
    return (
        <>
            <HeaderOne />
            <main>
                <div className="pd-r-l">
                    <HeroSlider />
                    <AboutArea />
                </div>
                <ComprehensiveSpeedArea />
                <ServiceArea />
                <div className="pd-r-l">
                    <TestimonialArea />
                </div>
                <Footer />
            </main>
        </>
    );
};

export default Home;

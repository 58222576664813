import React, { useState } from "react";
import { useTranslation } from "next-export-i18n";
import Image from "next/image";

import shape_1 from "@assets/img/home/video-bot-right.png";
import shape_2 from "@assets/img/home/video-top-left.png";
import video_img_1 from "@assets/img/platform/Opening-New-Campaign.png";

import VideoPopup from "@/src/modals/video-popup";

import RightSymbol from "@/src/svg/right-symbol";
import AngleArrow from "@/src/svg/angle-arrow_small";
import LineArrow from "@/src/svg/line-arrow-2";

const ComprehensiveSpeedArea = () => {
    const { t } = useTranslation();

    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState("");

    const shapes = [
        { id_cls: 1, img: shape_1 },
        { id_cls: 2, img: shape_2 },
    ];

    const data = {
        image: video_img_1,
        videoLink: "https://www.youtube.com/watch?v=djhd2snujjE",
        items: [
            t("home.video.items.item_1"),
            t("home.video.items.item_2"),
            t("home.video.items.item_3"),
        ],
    };

    const playVideo = (url: string) => {
        const regExp =
            /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*v=([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        setSelectedVideoId(match ? match[1] : "");
        setIsVideoOpen(true);
    };

    return (
        <>
            <section
                className="comprehensive-speed p-relative"
                style={{
                    backgroundImage: `url(/assets/img/home/intro-video-bg.jpg)`,
                    backgroundSize: "cover",
                }}
            >
                <div className="comprehensive-speed-wrapper">
                    <div className="comprehensive-speed-shape">
                        {shapes.map((shape, index) => (
                            <Image
                                key={index}
                                loading="eager"
                                className={`shape-${shape.id_cls} tp-hero-bg-img`}
                                src={shape.img}
                                alt="theme-pure"
                            />
                        ))}
                    </div>
                    <div className="comprehensive-speed-content">
                        <div>
                            <div className="tp-about-3-wrapper">
                                <span className="tp-section-title__pre comprehensive-speed-text">
                                    {t("home.video.subtitle.primary")}
                                    <span className="partners-title-secondary">
                                        {t("home.video.subtitle.secondary")}
                                    </span>
                                    <span className="comprehensive-speed-svg">
                                        <AngleArrow />
                                    </span>
                                </span>
                                <h3 className="tp-section-title partners comprehensive-speed-text comprehensive-speed-text-title">
                                    {t("home.video.title.primary")}
                                    <span className="partners-title-secondary">
                                        {t("home.video.title.secondary")}
                                    </span>
                                    <span className="comprehensive-speed-title-svg">
                                        <LineArrow />
                                    </span>
                                </h3>
                                <div>
                                    {data.items.map((item, index) => {
                                        const splitText = item.split(": ");
                                        return (
                                            <div
                                                key={index}
                                                className="platform__list-item mb-30"
                                            >
                                                <div>
                                                    <RightSymbol />
                                                </div>
                                                <span className="ml-20">
                                                    <strong>
                                                        {splitText[0]}
                                                    </strong>
                                                    :{" "}
                                                    <span>{splitText[1]}</span>
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="p-relative comprehensive-speed-image-wrapper">
                            <Image
                                src={data.image}
                                alt={"comprehensive-speed"}
                                className="platform-videos-area__block-image comprehensive-speed-image"
                            />
                            <div className="video-play">
                                <a
                                    className="popup-video"
                                    onClick={() => playVideo(data.videoLink)}
                                >
                                    <i className="fa-sharp fa-solid fa-play" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={selectedVideoId}
            />
        </>
    );
};

export default ComprehensiveSpeedArea;

import React, { useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-export-i18n";
import Image from "next/image";

import AngleArrow from "@/src/svg/angle-arrow_1";
import LineArrowTwo from "@/src/svg/line-arrow-2_1";
import service_img_1 from "@assets/img/services/intuitive-platform.svg";
import service_img_2 from "@assets/img/services/ai-powered-optimization.svg";
import service_img_3 from "@assets/img/services/hyper-local-targeting.svg";
import service_img_4 from "@assets/img/services/multi-channel-reach.svg";
import service_img_5 from "@assets/img/services/robust-analytics.svg";
import service_img_6 from "@assets/img/services/device-tracking.svg";
import service_img_7 from "@assets/img/services/budget-control.svg";
import service_img_8 from "@assets/img/services/interest-based-targeting.svg";
import service_img_9 from "@assets/img/services/precision_demographic_targeting.svg";
import service_img_10 from "@assets/img/services/ai-based-targeting.svg";
import service_img_11 from "@assets/img/services/uncompromising-privacy.svg";

const ServiceArea = () => {
    const { t } = useTranslation();
    const [openedAccordion, setOpenedAccordion] = useState<number | null>(null);

    const switchAccordion = (id: number) => () =>
        setOpenedAccordion(openedAccordion === id ? null : id);

    const service_data = [
        {
            id: 1,
            title: t("home.services.blocks.block_1.title"),
            img: service_img_1,
            description: t("home.services.blocks.block_1.description"),
        },
        {
            id: 2,
            title: t("home.services.blocks.block_2.title"),
            img: service_img_2,
            description: t("home.services.blocks.block_2.description"),
        },
        {
            id: 3,
            title: t("home.services.blocks.block_3.title"),
            img: service_img_3,
            description: t("home.services.blocks.block_3.description"),
        },
        {
            id: 4,
            title: t("home.services.blocks.block_4.title"),
            img: service_img_4,
            description: t("home.services.blocks.block_4.description"),
        },
        {
            id: 5,
            title: t("home.services.blocks.block_5.title"),
            img: service_img_5,
            description: t("home.services.blocks.block_5.description"),
        },
        {
            id: 6,
            title: t("home.services.blocks.block_6.title"),
            img: service_img_6,
            description: t("home.services.blocks.block_6.description"),
        },
        {
            id: 7,
            title: t("home.services.blocks.block_7.title"),
            img: service_img_7,
            description: t("home.services.blocks.block_7.description"),
        },
        {
            id: 8,
            title: t("home.services.blocks.block_8.title"),
            img: service_img_8,
            description: t("home.services.blocks.block_8.description"),
        },
        {
            id: 9,
            title: t("home.services.blocks.block_9.title"),
            img: service_img_9,
            description: t("home.services.blocks.block_9.description"),
        },
        {
            id: 10,
            title: t("home.services.blocks.block_10.title"),
            img: service_img_10,
            description: t("home.services.blocks.block_10.description"),
        },
        {
            id: 11,
            title: t("home.services.blocks.block_11.title"),
            img: service_img_11,
            description: t("home.services.blocks.block_11.description"),
        },
    ];

    return (
        <>
            <div className="tp-service-funfact-box">
                <section className="tp-service-area pt-85 pb-50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tp-service-title-wrapper text-center">
                                    <span className="tp-section-title__pre">
                                        {t("home.services.subtitle.primary")}
                                        <span className="services_text">
                                            {t(
                                                "home.services.subtitle.secondary"
                                            )}
                                        </span>
                                        <span className="services_right-shape">
                                            <AngleArrow />
                                        </span>
                                    </span>
                                    <h3 className="tp-section-title">
                                        <span className="services_text">
                                            {t("home.services.title.secondary")}
                                        </span>
                                        {t("home.services.title.primary")}
                                        <span className="services_center-shape">
                                            <LineArrowTwo />
                                        </span>
                                    </h3>
                                </div>
                            </div>

                            <div className="tp-service-slider-wrapper">
                                <div className="tp-faq-tab-content tp-accordion">
                                    <div
                                        className="accordion"
                                        id="general_accordion"
                                    >
                                        {service_data.map((item, i) => {
                                            const isOpened =
                                                openedAccordion === item.id;
                                            return (
                                                <div
                                                    key={item.id}
                                                    id={`accordion-item${item.id}`}
                                                    className={`accordion-item tp-service-wrapper ${
                                                        isOpened
                                                            ? ""
                                                            : "collapsed"
                                                    }`}
                                                >
                                                    <div
                                                        className="accordion-header"
                                                        id={`heading${item.id}`}
                                                    >
                                                        <button
                                                            className={`accordion-button tp-service-title ${
                                                                isOpened
                                                                    ? ""
                                                                    : "collapsed"
                                                            }`}
                                                            type="button"
                                                            onClick={switchAccordion(
                                                                item.id
                                                            )}
                                                        >
                                                            <div className="tp-service-icon d-flex align-items-center">
                                                                <Image
                                                                    src={
                                                                        item.img
                                                                    }
                                                                    alt="theme-pure"
                                                                    width={46}
                                                                    height={46}
                                                                />
                                                            </div>
                                                            <div>
                                                                <h3 className="service-title">
                                                                    {
                                                                        item
                                                                            .title
                                                                            .line_1
                                                                    }{" "}
                                                                    {
                                                                        item
                                                                            .title
                                                                            .line_2
                                                                    }
                                                                </h3>
                                                                <div className="tp-service-btn">
                                                                    {t(
                                                                        isOpened
                                                                            ? "home.services.read_less"
                                                                            : "home.services.read_more"
                                                                    )}
                                                                    <i className="fa-solid fa-arrow-up-right" />
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={`collapse${item.id}`}
                                                        className={`accordion-collapse collapse ${
                                                            isOpened
                                                                ? "show"
                                                                : ""
                                                        }`}
                                                        aria-labelledby={`heading${item.id}`}
                                                    >
                                                        <div className="accordion-body tp-service-description">
                                                            {item.description}
                                                            <div className="tp-service-link-btn text-center fadeUp">
                                                                <Link
                                                                    className="tp-btn"
                                                                    href="/platform"
                                                                >
                                                                    {t(
                                                                        "home.services.btn"
                                                                    )}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default ServiceArea;
